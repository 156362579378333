export const isEDMode =
    typeof window !== 'undefined' ? window.location.href.indexOf('ed=1') > 0 : false;
export const mobileMedia = '(min-width: 0px) and (max-width: 1200px)';
export const tabletMedia = '(min-width: 768px) and (max-width: 1200px)';

export const isMobile = (media = false) => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(media || mobileMedia).matches;
    }

    return true;
};

export const isTablet = () => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(tabletMedia).matches;
    }

    return true;
};

export const bounds = (elem) => {
    const rect = elem.getBoundingClientRect();

    return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
    };
};

export const getWinSize = () => {
    if (typeof window !== 'undefined') {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;
        const y = w.innerHeight || e.clientHeight || g.clientHeight;

        return { width: x, height: y };
    }

    return { width: 0, height: 0 };
};

export const inViewport = (elem, entireBox = false, xOffset = 0, yOffset = 0) => {
    if (typeof window !== 'undefined') {
        const bds = bounds(elem);
        const doc = getWinSize();

        if (!entireBox) {
            return (
                bds.top >= 0 &&
                bds.left >= 0 &&
                bds.top <= doc.height &&
                bds.left <= doc.width
            );
        } else {
            const topLimit = bds.top - doc.height;
            const bottomLimit = bds.top + bds.height;
            const leftLimit = bds.left - doc.width;
            const rightLimit = bds.left + bds.width;

            if (topLimit <= 0 && bottomLimit >= 0 && leftLimit <= 0 && rightLimit >= 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
};

const timeoutDebounce = {};
export const debounce = (name, func, wait, immediate) => {
    return function () {
        const context = this;
        const args = arguments;

        const later = () => {
            timeoutDebounce[name] = null;

            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeoutDebounce[name];

        clearTimeout(timeoutDebounce[name]);

        timeoutDebounce[name] = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
};

export const getOS = () => {
    let OSName = 'unknown-os';

    if (navigator.appVersion.includes('Win')) OSName = 'win';
    if (navigator.appVersion.includes('Mac')) OSName = 'mac';
    if (navigator.appVersion.includes('X11')) OSName = 'unix';
    if (navigator.appVersion.includes('iPhone')) OSName = 'ios';
    if (navigator.appVersion.includes('iPad')) OSName = 'ios';
    if (navigator.appVersion.includes('Linux')) OSName = 'linux';

    return OSName;
};

export const offset = (elem) => {
    if (typeof window !== 'undefined') {
        const rect = bounds(elem);
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    return { top: 0, left: 0 };
};

export const isExactPath = (path) => {
    if (typeof window !== 'undefined') {
        return window.location.pathname === path;
    }
};

export const focusLoop = (e, selector) => {
    const tabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!tabPressed) return;

    const elems = document.querySelectorAll(selector);

    if (elems?.length) {
        const firstElem = elems[0];
        const lastElem = elems[elems.length - 1];

        if (!Array.from(elems).includes(document.activeElement)) {
            firstElem.focus();
        }

        if (e.shiftKey) {
            if (document.activeElement === firstElem) {
                lastElem.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastElem) {
                firstElem.focus();
                e.preventDefault();
            }
        }
    }
};

export const getCookieByName = (cookieName) => {
    const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName + '='));
    if (cookieValue === undefined) {
        return null;
    }
    return cookieValue.split('=')[1];
};

export const checkSettingsBtns = () => {
    const allowAllBtn = document.body.querySelector('#accept-recommended-btn-handler');
    const targetChecked = document.querySelector('#ot-group-id-C0002').checked;
    const performanceChecked = document.querySelector('#ot-group-id-C0004').checked;

    if (targetChecked && performanceChecked) {
        allowAllBtn.style.display = 'none';
    } else {
        allowAllBtn.style.display = 'inline-block';
    }
};

export const getUrlParameter = (param) => {
    param = param.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    const results = regex.exec(window.location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const scrollToSection = (selector, isSmooth, withOffset = false) => {
    const el = document.querySelector(selector);
    if (el) {
        if (!withOffset) {
            el.scrollIntoView({
                behavior: isSmooth ? 'smooth' : 'auto',
            });
        } else {
            const offset = isMobile() ? 151 : 106; // 151(106) is the height of the sticky header on mobile(desktop) devices
            const elementPosition =
                el.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: elementPosition,
                behavior: isSmooth ? 'smooth' : 'auto',
            });
        }
    }
};

export const openCookieModal = () => {
    if (typeof window !== 'undefined' && 'OneTrust' in window) {
        window.OneTrust.ToggleInfoDisplay();
    }
};

export const ssCookieModalFullHeightFilter = () => {
    // Cookie preferences modal
    if (window.location.href.includes('cookiePcCenterHeight=1')) {
        const container = document.querySelector('#onetrust-pc-sdk');
        if (container && container?.style.display !== 'none') {
            const header = document.querySelector('.ot-pc-header');
            const pcContent = document.querySelector('#ot-pc-content');
            const footer = document.querySelector('.ot-pc-footer');

            if (container && header && pcContent && footer) {
                pcContent.style.overflow = 'visible';
                pcContent.style.height = 'max-content';
                const height =
                    header.offsetHeight +
                    pcContent.offsetHeight +
                    footer.offsetHeight +
                    50;
                container.style.setProperty('height', height + 'px', 'important');
                container.style.setProperty('max-height', height + 'px', 'important');

                if (isMobile()) {
                    container.style.position = 'absolute';
                    const elements = document.querySelectorAll(
                        'html, body, #___gatsby, #gatsby-focus-wrapper'
                    );
                    elements.forEach((el) => {
                        el.style.setProperty('overflow', 'hidden', 'important');
                        el.style.setProperty('height', height + 'px', 'important');
                        el.style.setProperty('max-height', height + 'px', 'important');
                    });
                }
            }
        }
    } else if (window.location.href.includes('cookie-settings-all-open=1')) {
        // Cookie preferences modal - details open
        const container = document.querySelector('#onetrust-pc-sdk');
        if (container && container?.style.display !== 'none') {
            const filtersSection = document.querySelector('#ot-pc-lst');
            const filters = document.querySelector('#ot-lst-cnt');
            const height = document.querySelector(
                '#ot-lst-cnt > .ot-sdk-row'
            )?.offsetHeight;

            if (filtersSection && filters && height) {
                filters.style.setProperty('height', height + 50 + 'px', 'important');
                filters.style.setProperty('max-height', 'unset', 'important');
                filtersSection.style.setProperty(
                    'height',
                    height + 50 + 'px',
                    'important'
                );
                filtersSection.style.setProperty('max-height', 'unset', 'important');
                container.style.setProperty('height', height + 250 + 'px', 'important');
                container.style.setProperty(
                    'min-height',
                    height + 250 + 'px',
                    'important'
                );

                if (isMobile()) {
                    container.style.setProperty(
                        'max-height',
                        height + 250 + 'px',
                        'important'
                    );
                    container.style.setProperty('min-height', '100vh', 'important');
                    container.style.position = 'absolute';

                    const elements = document.querySelectorAll(
                        'html, body, #___gatsby, #gatsby-focus-wrapper'
                    );
                    elements.forEach((el) => {
                        el.style.setProperty('overflow', 'hidden', 'important');
                        el.style.setProperty('height', height + 250 + 'px', 'important');
                        el.style.setProperty(
                            'max-height',
                            height + 250 + 'px',
                            'important'
                        );
                        el.style.setProperty('min-height', '100vh', 'important');
                    });
                }
            }
        }
    } else {
        if (isMobile()) {
            const elements = document.querySelectorAll(
                'html, body, #___gatsby, #gatsby-focus-wrapper'
            );
            elements.forEach((el) => {
                el.style.setProperty('overflow', 'hidden', 'important');
                el.style.setProperty('height', '100vh', 'important');
                el.style.setProperty('min-height', '100vh', 'important');
                el.style.setProperty('max-height', '100vh', 'important');
            });
        }
    }
};

export const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};

export const extractTextFromElement = (element) => {
    if (element?.props && element?.props?.children) {
        if (typeof element.props.children === 'string') {
            return element.props.children;
        } else if (Array.isArray(element.props.children)) {
            const textContentArray = element.props.children.map((child) => {
                if (typeof child === 'string') {
                    return child;
                } else if (typeof child === 'object' && child !== null) {
                    return extractTextFromElement(child);
                } else {
                    return '';
                }
            });

            return textContentArray.join(' ');
        }
    } else {
        return element;
    }
};
