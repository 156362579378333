import React, { useState } from "react"

const defaultState = {
    modalOpen: false,
    setModalOpen: () => {},
    externalUrl: null,
    setExternalUrl: () => {},
    modalType: null,
    setModalType: () => {},
}

const AppContext = React.createContext(defaultState)

const AppProvider = ({ children }) => {
    const [modalOpen, setModalOpen] = useState(defaultState.modalOpen);
    const [url, setUrl] = useState(defaultState.externalUrl);
    const [modalType, setModalType] = useState(defaultState.modalType);

    const store = {
        modalOpen: modalOpen,
        setModalOpen: setModalOpen,
        externalUrl: url,
        setExternalUrl: setUrl,
        modalType: modalType,
        setModalType: setModalType,
    };
    return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

export default AppContext

export { AppProvider }
