import React from 'react';
import { AppProvider } from './src/context';
import GTM from '@utils/GTM.js';

export const shouldUpdateScroll = () => {
    window.scrollTo(0, 0);
    return false;
};

export const onRouteUpdate = () => {
    GTM.reset();
};

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;
